import axios from 'axios';
import JsCookie from 'js-cookie';
import Router from 'next/router';

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    if (JsCookie.get('Authorization')) {
        config.headers.Authorization = JsCookie.get('Authorization');
    }

    config.headers.Accept = 'application/json';

    return config;
});

axios.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data

        return response;
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if (process.browser) {
            // const store = getStore();

            if (error?.response?.status === 401) {
                JsCookie.remove('Authorization');
                Router.push('/login?session_expired=true');

                return Promise.resolve();
            }
            // else if (error?.response?.status === 500 && store) {
            //     const { dispatch } = store;
            //     dispatch(setGlobalError(500));
            // }
        }

        return Promise.reject(error);
    },
);

export default axios;

import debounce from 'debounce';
import qs from 'qs';
import { getPrivateOrderChatSelector } from 'src/selectors/private-order-chat-selector';

import axios from '@utils/axios';

const prefix = '@PRIVATE_ORDER_CHAT';

export const SET_PRIVATE_ORDER_CHAT_DATA = `${prefix}/SET_PRIVATE_ORDER_CHAT_DATA`;
export const SET_IS_GET_PRIVATE_ORDER_CHAT_REQUEST_PROCESSING = `${prefix}/SET_IS_GET_PRIVATE_ORDER_CHAT_REQUEST_PROCESSING`;
export const SET_GET_PRIVATE_ORDER_CHAT_REQUEST_ERROR = `${prefix}/SET_GET_PRIVATE_ORDER_CHAT_REQUEST_ERROR`;
export const PRIVATE_ORDER_CHAT_READ_MESSAGES = `${prefix}/PRIVATE_ORDER_CHAT_READ_MESSAGES`;
export const PRIVATE_ORDER_CHAT_SEND_MESSAGE = `${prefix}/PRIVATE_ORDER_CHAT_SEND_MESSAGE`;
export const SET_IS_SEND_MESSAGE_PRIVATE_ORDER_CHAT_REQUEST_PROCESSING = `${prefix}/SET_IS_SEND_MESSAGE_PRIVATE_ORDER_CHAT_REQUEST_PROCESSING`;
export const SET_SEND_MESSAGE_PRIVATE_ORDER_CHAT_REQUEST_ERROR = `${prefix}/SET_SEND_MESSAGE_PRIVATE_ORDER_CHAT_REQUEST_ERROR`;
export const ADD_NEW_PRIVATE_ORDER_CHAT_UNREAD_MESSAGE = `${prefix}/ADD_NEW_PRIVATE_ORDER_CHAT_UNREAD_MESSAGE`;
export const ADD_NEW_PRIVATE_ORDER_CHAT_READ_MESSAGE = `${prefix}/ADD_NEW_PRIVATE_ORDER_CHAT_READ_MESSAGE`;
export const PRIVATE_ORDER_CHAT_SET_MESSAGES_READ = `${prefix}/PRIVATE_ORDER_CHAT_SET_MESSAGES_READ`;
export const PRIVATE_ORDER_CHAT_SET_SEARCH = `${prefix}/PRIVATE_ORDER_CHAT_SET_SEARCH`;
export const PRIVATE_ORDER_CHAT_SET_RESET = `${prefix}/PRIVATE_ORDER_CHAT_SET_RESET`;
export const PRIVATE_ORDER_CHAT_UPDATE_MESSAGE = `${prefix}/PRIVATE_ORDER_CHAT_UPDATE_MESSAGE`;

const setIsGetPrivateOrderChatRequestProcessingAction = isGetPrivateChatRequestProcessing => ({
    type: SET_IS_GET_PRIVATE_ORDER_CHAT_REQUEST_PROCESSING,
    payload: isGetPrivateChatRequestProcessing,
});

const setGetPrivateChatRequestErrorAction = error => ({
    type: SET_GET_PRIVATE_ORDER_CHAT_REQUEST_ERROR,
    payload: error,
});

const setDataOrderAction = data => ({ type: SET_PRIVATE_ORDER_CHAT_DATA, payload: data });

export const getPrivateOrderChatAction = (orderId, chatId) => async (dispatch, getState) => {
    const state = getState();
    const { search, currentPage, readMessages, getPrivateOrderChatRequest } = getPrivateOrderChatSelector(state);
    const { isProcessing } = getPrivateOrderChatRequest;

    if (isProcessing) {
        return;
    }

    dispatch(setIsGetPrivateOrderChatRequestProcessingAction(true));
    try {
        const queryParams = { search, page: readMessages.length ? currentPage + 1 : currentPage };

        const { data } = await axios.get(`/api/orders/${orderId}/chats/${chatId}?${qs.stringify(queryParams)}`);

        data.orderId = orderId;
        data.chatId = chatId;

        dispatch(setDataOrderAction(data));
    } catch (error) {
        dispatch(setGetPrivateChatRequestErrorAction(error));
    }

    dispatch(setIsGetPrivateOrderChatRequestProcessingAction(false));
};

let messages = [];

export const clearReadMessagesQueue = () => (messages = []);

const readMessages = debounce(async (dispatch, getState) => {
    if (!messages.length) {
        return;
    }

    const { orderId, chatId } = getPrivateOrderChatSelector(getState());

    try {
        await axios.post(`/api/orders/${orderId}/chats/${chatId}/messages/read`, { messages });

        dispatch({ type: PRIVATE_ORDER_CHAT_READ_MESSAGES, payload: messages });
        messages = [];
    } catch (ex) {}
}, 300);

export const readOrderMessagesAction = messagesIds => async (dispatch, getState) => {
    messagesIds.forEach(messageId => {
        if (messages.includes(messageId)) {
            return;
        }

        messages.push(messageId);
    });

    readMessages(dispatch, getState);
};

export const readMessageAction = messageId => readOrderMessagesAction([messageId]);

export const setIsSendMessageRequestProcessing = isSendMessageRequestProcessing => ({
    type: SET_IS_SEND_MESSAGE_PRIVATE_ORDER_CHAT_REQUEST_PROCESSING,
    payload: isSendMessageRequestProcessing,
});

export const setSendMessageRequestError = sendMessageRequestError => ({
    type: SET_SEND_MESSAGE_PRIVATE_ORDER_CHAT_REQUEST_ERROR,
    payload: sendMessageRequestError,
});

export const sendMessage = (orderId, chatId, message) => async (dispatch, getState) => {
    dispatch(setIsSendMessageRequestProcessing(true));

    try {
        const { content, files } = message;
        let body = new FormData();

        body.append('content', content);
        files.forEach(file => body.append('files[]', file));

        const { data } = await axios.post(`/api/orders/${orderId}/chats/${chatId}/messages`, body);

        const state = getState();
        const { unreadMessages } = getPrivateOrderChatSelector(state);

        if (unreadMessages.length) {
            dispatch(readOrderMessagesAction(unreadMessages.map(({ id }) => id)));
        }

        dispatch({ type: PRIVATE_ORDER_CHAT_SEND_MESSAGE, payload: data });
    } catch (ex) {}

    dispatch(setIsSendMessageRequestProcessing(false));
};

export const addNewUnreadMessage = message => ({
    type: ADD_NEW_PRIVATE_ORDER_CHAT_UNREAD_MESSAGE,
    payload: message,
});

export const addNewReadMessage = message => ({
    type: ADD_NEW_PRIVATE_ORDER_CHAT_READ_MESSAGE,
    payload: message,
});

export const setMessagesRead = messagesId => ({
    type: PRIVATE_ORDER_CHAT_SET_MESSAGES_READ,
    payload: messagesId,
});

export const updateMessageCounter = () => ({
    type: PRIVATE_ORDER_CHAT_UPDATE_MESSAGE,
    payload: 1,
});

export const setSearch = search => ({
    type: PRIVATE_ORDER_CHAT_SET_SEARCH,
    payload: search,
});

export const resetPrivateChat = () => ({ type: PRIVATE_ORDER_CHAT_SET_RESET });

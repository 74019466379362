import React from 'react';

import CheckSvg from '@images/check.svg';
import DoubleCheckSvg from '@images/double-check.svg';

import './time-message.scss';

const TimeMessage = ({ className, time, isRead, isMine, hideReadMark }) => {
    return <div className={className}>
        {!hideReadMark && (isMine && (isRead ? <DoubleCheckSvg className='message-info__read' /> : <CheckSvg className='message-info__unread' />))}
        <span className='message-info__message-time'>{time}</span>
    </div>;
};

export default TimeMessage;

import debounce from 'debounce';
import qs from 'qs';

import { getGeneralChatSelector } from '@selectors/general-chat-selectors';
import axios from '@utils/axios';

const prefix = '@GENERAL_CHAT';

export const SET_GENERAL_CHAT_DATA = `${prefix}/SET_CHAT_DATA`;
export const GET_GENERAL_CHAT_DATA = `${prefix}/GET_CHAT_DATA`;
export const SET_IS_GET_CHAT_REQUEST_PROCESSING = `${prefix}/SET_IS_GET_CHAT_REQUEST_PROCESSING`;
export const SET_GET_CHAT_REQUEST_ERROR = `${prefix}/SET_GET_CHAT_REQUEST_ERROR`;
export const READ_MESSAGES = `${prefix}/READ_MESSAGES`;
export const SEND_MESSAGE = `${prefix}/SEND_MESSAGE`;
export const SET_IS_SEND_MESSAGE_REQUEST_PROCESSING = `${prefix}/SET_IS_SEND_MESSAGE_REQUEST_PROCESSING`;
export const SET_SEND_MESSAGE_REQUEST_ERROR = `${prefix}/SET_SEND_MESSAGE_REQUEST_ERROR`;
export const ADD_NEW_UNREAD_MESSAGE = `${prefix}/ADD_NEW_UNREAD_MESSAGE`;
export const ADD_NEW_READ_MESSAGE = `${prefix}/ADD_NEW_READ_MESSAGE`;
export const SET_MESSAGES_READ = `${prefix}/SET_MESSAGES_READ`;
export const SET_SEARCH = `${prefix}/SET_SEARCH`;
export const RESET = `${prefix}/RESET`;

export const setGeneralChatDataAction = data => ({
    type: SET_GENERAL_CHAT_DATA,
    payload: data,
});

export const setIsGetChatRequestProcessing = isGetChatRequestProcessing => ({
    type: SET_IS_GET_CHAT_REQUEST_PROCESSING,
    payload: isGetChatRequestProcessing,
});

export const getGeneralChatDataAction = caseId => async (dispatch, getState) => {
    const state = getState();
    const { search, currentPage, readMessages, lastPage, getChatDataRequest } = getGeneralChatSelector(state);
    const { isProcessing } = getChatDataRequest;

    if (currentPage >= lastPage || isProcessing) {
        return;
    }

    dispatch(setIsGetChatRequestProcessing(true));
    try {
        const queryParams = { search, page: readMessages.length ? currentPage + 1 : currentPage };
        const { data } = await axios.get(`/api/legal_cases/${caseId}/general_chat?${qs.stringify(queryParams)}`);

        data.caseId = caseId;
        dispatch(setGeneralChatDataAction(data));
    } catch (ex) {}

    dispatch(setIsGetChatRequestProcessing(false));
};

let messages = [];

export const clearReadMessagesQueue = () => (messages = []);

const readMessages = debounce(async (dispatch, getState) => {
    const { caseId, chatId } = getGeneralChatSelector(getState());

    try {
        await axios.post(`/api/legal_cases/${caseId}/${chatId}/messages/read`, { messages });

        dispatch({ type: READ_MESSAGES, payload: messages });
        messages = [];
    } catch (ex) {}
}, 300);

export const readMessagesAction = messagesIds => async (dispatch, getState) => {
    messagesIds.forEach(messageId => {
        if (messages.includes(messageId)) {
            return;
        }

        messages.push(messageId);
    });

    readMessages(dispatch, getState);
};

export const readMessageAction = messageId => readMessagesAction([messageId]);

export const setIsSendMessageRequestProcessing = isSendMessageRequestProcessing => ({
    type: SET_IS_SEND_MESSAGE_REQUEST_PROCESSING,
    payload: isSendMessageRequestProcessing,
});

export const setSendMessageRequestError = sendMessageRequestError => ({
    type: SET_SEND_MESSAGE_REQUEST_ERROR,
    payload: sendMessageRequestError,
});

export const sendMessage = (caseId, chatId, message) => async (dispatch, getState) => {
    dispatch(setIsSendMessageRequestProcessing(true));

    try {
        const { content, files } = message;
        let body = new FormData();

        body.append('content', content);
        files.forEach(file => body.append('files[]', file));

        const { data } = await axios.post(`/api/legal_cases/${caseId}/${chatId}/messages`, body);

        const state = getState();
        const { unreadMessages } = getGeneralChatSelector(state);

        if (unreadMessages.length) {
            dispatch(readMessagesAction(unreadMessages.map(({ id }) => id)));
        }

        dispatch({ type: SEND_MESSAGE, payload: data });
    } catch (ex) {}

    dispatch(setIsSendMessageRequestProcessing(false));
};

export const addNewUnreadMessage = message => ({
    type: ADD_NEW_UNREAD_MESSAGE,
    payload: message,
});

export const addNewReadMessage = message => ({
    type: ADD_NEW_READ_MESSAGE,
    payload: message,
});

export const setMessagesRead = messagesId => ({
    type: SET_MESSAGES_READ,
    payload: messagesId,
});

export const setSearch = search => ({
    type: SET_SEARCH,
    payload: search,
});

export const resetGeneralChat = () => ({ type: RESET });
